<template>
  <div>
    <div class="header">
      <img src="../assets/logo.png" class="logo">
      <div class="menu">
        <div class="menu-item gap"></div>
        <div to="/" class="menu-item" :class="($route.path == '/' || $router.path == '/declaraction') ? 'active-menu' : ''">
          <router-link to="/">首页</router-link>
        </div>
        <div class="menu-item" :class="($route.path == '/new-energy' || $route.path == '/ipo-service' || $route.path == '/vip-service' || $route.path == '/headquarters' || $route.path == '/resource' || $route.path == '/news' || $route.path == '/membership') ? 'active-menu' : ''">
          <router-link to="/new-energy">师董服务</router-link>
        </div>
        <div class="menu-item" :class="$route.path == '/download' ? 'active-menu' : ''">
          <router-link to="/download">下载客户端</router-link>
        </div>
        <div class="menu-item lang-item" @mouseover="showDropdown=true" @mouseout="showDropdown=false">
          <div class="menu-item lang-item" :class="$route.path == '/about-us' ? 'active-menu' : ''" @click="goaboutus">关于我们</div>
          <ul class="dropdown-menu" v-show="showDropdown">
            <li class="dropdown-menu-item" :class="$route.path == '/about-us' ? 'active-menu' : ''">
              <router-link to="/about-us">了解我们</router-link>
            </li>
            <li class="dropdown-menu-item" :class="$route.path == '/join-us' ? 'active-menu' : ''">
              <router-link to="/join-us">加入我们</router-link>
            </li>
            <li class="dropdown-menu-item">
              <a target="_blank" href="https://www.sdh365.com/IR/IRindex.html">投资者关系</a>
            </li>
            <div class="popper-arrow"></div>
          </ul>
        </div>
      </div>
      <a target="_blank" href="http://www.sdh365.com/en/">
        <img src="../assets/language.png" class="language">
      </a>
    </div>
    <div class="second-header">
      <div class="menu-item" :class="$route.path == '/new-energy' ? 'active-menu' : ''" >
        <router-link to="/new-energy">晖阳新能源</router-link>
      </div>
      <div class="menu-item" :class="$route.path == '/ipo-service' ? 'active-menu' : ''" >
        <router-link to="/ipo-service">上市服务</router-link>
      </div>
      <div class="menu-item" :class="($route.path == '/vip-service' || $route.path == '/membership')? 'active-menu' : ''">
        <router-link to="/vip-service">会员服务</router-link>
      </div>
      <div class="menu-item" :class="$route.path == '/headquarters' ? 'active-menu' : ''">
        <router-link to="/headquarters">区域总部</router-link>
      </div>
      <div class="menu-item" :class="$route.path == '/resource' ? 'active-menu' : ''">
        <router-link to="/resource">优质资源</router-link>
      </div>
      <div class="menu-item" :class="$route.path == '/news' ? 'active-menu' : ''">
        <router-link to="/news">新闻动态</router-link>
      </div>
      <div class="menu-item">
        <a target="_blank" href="https://www.sdh365.com/IR/IRindex.html" style="text-decoration:underline">投资者关系</a>
      </div>
      <img src="../assets/code.png" class="code">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data(){
    return{
      activeMenu: '',
      activeSecondMenu: '',
      showDropdown: false
    }
  },
  created(){
    document.addEventListener('click', (e) => {
        if (document.getElementsByClassName('lang-item')[0]) {
          //这句话是说如果我们点击到了class为keywordContainer以外的区域
          if (!document.getElementsByClassName('lang-item')[0].contains(e.target)) {
            this.showDropdown = false;
          }
        }
    })
  },
  computed:{
  },
  methods:{
    clickMenu(title){
      this.activeMenu = title
      if(title == 'ipo-service'){
        this.activeSecondMenu = 'ipo-service'
      }
      else{
        this.activeSecondMenu = ''
      }
    },
    clickSecondMenu(title){
      this.activeSecondMenu = title
      this.activeMenu = 'ipo-service'
    },
    goaboutus(){
      this.$router.push("/about-us");
    }
  }
}
</script>

<style lang="less" scoped>
.header{
  margin-left: 360px;
  margin-right: 360px;
}
.second-header{
  line-height: 54px;
  background: #f5f5f5;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.10); 
  font-size: 18px;
  color: #333333;
  padding-left: 360px;
}
.logo{
  float: left;
  width: 124px;
  height: 49px;
  margin-top: 16px;
  margin-right: 23px;
}
.language{
  float: right;
  width: 86px;
  height: 34px;
  margin-top: 23px;
}
.code{
  float: right;
  width: 207px;
  height: 42px;
  margin-top: 8px;
  margin-right: 348px;
}
.menu{
  display: inline-block;
  width: auto;
  font-size: 20px;
  line-height: 80px;
}
.menu-item{
  display: inline-block;
  margin-right: 44px;
  color: #333333;
  a{
    height: 100%;
  }
}
.menu-item:hover{
  color: #ff7b00;
}
.lang-item{
  cursor: pointer;
}
.dropdown-menu{
  position: absolute;
  top: 55px;
  left: 870px;
  padding: 10px 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15); 
  font-size: 16px;
  z-index: 999;
}
.dropdown-menu-item{
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  outline: none;
  a{
    display: block;
  }
}
.dropdown-menu-item:hover{
  color: #ff7b00;
}
.popper-arrow{
  top: -6px;
  left: 45%;
  margin-right: 3px;
  border-top-width: 0;
  border-bottom-color: #ebeef5;
  border-width: 6px;
  filter: drop-shadow(0 2px 12px rgba(0,0,0,.03));
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.popper-arrow::after{
  content: "";
  border-width: 6px;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: -6px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.active-menu{
  color: #ff7b00;
}
.gap{
  position: relative;
  margin-right: 20px;
}
.gap::after{
  content: "";
  position: absolute;
  top: -16px;
  width: 1px;
  height: 16px;
  background: #eeeeee;
}
</style>

<style>
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 0px !important;
}
.el-menu.el-menu--horizontal{
  border-bottom: 0px !important;
}
</style>
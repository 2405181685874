<template>
  <div id="app">
    <Header></Header>
    <keep-alive include="More-tutor">
      <router-view></router-view>
    </keep-alive>
    <Footer></Footer>
    <Side></Side>
    <FloatCode></FloatCode>
    <!-- <Flutter></Flutter> -->
    <Chat></Chat>
    <Service></Service>
    <PopMessage></PopMessage>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import Side from "./components/side.vue";
import FloatCode from "./components/float-code.vue";
// import Flutter from "./components/flutter";
import Chat from "./components/chat";
import Service from "./components/service";
import PopMessage from "./components/popMessage";
import request from "@/utils/request";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    Side,
    FloatCode,
    // Flutter,
    Chat,
    Service,
    PopMessage,
  },
  data() {
    return {
      pageList: {
        "/": "首页",
        "/new-energy": "晖阳新能源",
        "/ipo-service": "上市服务",
        "/vip-service": "会员服务",
        "/headquarters": "区域总部",
        "/resource": "优质资源",
        "/news": "新闻动态",
        "/download": "下载客户端",
        "/about-us": "了解我们",
        "/join-us": "加入我们",
        "/news-detail": "新闻详情",
        "/more-tutor": "更多导师",
        "/tutor-detail": "导师详情",
        "/declaraction": "免责声明"
      },
    };
  },
  watch: {
    $route(to) {
      request.post("in/website/browse", { title: this.pageList[to.path] });
    },
  },
};
</script>

<style>
.side,
.float {
  position: relative;
  z-index: 99;
}
</style>
<template>
<div>
    <div class="side">
        <div class="side-box">
            <div class="side-btn" @mouseover="app=true" @mouseout="app=false">
                <div class="download"></div>
                <span>APP下载</span>
                <img v-show="app" class="app" src="../assets/qrcode_app.png">
            </div>
            <div class="side-btn" @click="showChat">
                <div class="kefu"></div>
                <span>在线咨询</span>
            </div>
            <div class="side-btn" @mouseover="gzh=true" @mouseout="gzh=false">
                <div class="wechat"></div>
                <span>微信咨询</span>
                <img v-show="gzh" class="gzh" src="../assets/qrcode_gzh.png">
            </div>
            <div class="side-btn" @click="showPopMessage">
                <div class="message"></div>
                <span>留言合作</span>
            </div>
        </div>
        <div class="top" @click="backtop"></div>
    </div>
</div>
</template>

<script>
export default {
    name:"Side",
    data(){
        return{
            app: false,
            gzh: false,
        }
    },
    methods:{
        backtop(){
          var timer = setInterval(function(){
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-osTop / 5); 
            document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
            this.isTop = true;
            if(osTop === 0){
              clearInterval(timer);
            }
          },30)
        },
        showChat(){
            this.$store.commit('showChat')
        },
        showPopMessage(){
            this.$store.commit('showPopMessage')
        }
    }
}
</script>

<style lang="less" scoped>
.side{
    position: fixed;
    top: 275px;
    right: 50px;
    z-index: 999;
    .app{
        position: absolute;
        width: 198px;
        top: -35px;
        left: -205px;
    }
    .gzh{
        position: absolute;
        width: 198px;
        top: -45px;
        left: -205px;
    }
}
.side-box{
    width: 70px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25); 
    font-size: 13px;
    color: #333333;
    .side-btn{
        border-bottom: 1px solid #eeeeee;
        padding: 15px 8px 13px 8px;
        cursor: pointer;
        text-align: center;
        position: relative;
        .download,.wechat,.kefu,.message{
            height: 32px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 5px;
        }
        .download{
            background-image: url("../assets/download.png");
        }
        .wechat{
            background-image: url("../assets/wechat.png");
        }
        .kefu{
            background-image: url("../assets/kefu.png");
        }
        .message{
            background-image: url("../assets/message.png");
        }
    }
    .side-btn:last-child{
        border: none;
    }
    .side-btn:hover{
        background-color: #FF7B00;
        color: #ffffff;
        .download{
            background-image: url("../assets/download_w.png");
        }
        .wechat{
            background-image: url("../assets/wechat_w.png");
        }
        .kefu{
            background-image: url("../assets/kefu_w.png");
        }
        .message{
            background-image: url("../assets/message_w.png");
        }
    }
    .side-btn:first-child:hover{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .side-btn:last-child:hover{
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}
.top{
    display: inline-block;
    width: 70px;
    height: 70px;
    border-radius: 8px;
    margin-top: 10px;
    background: url("../assets/top.png");
    background-position: center;
    background-size: contain;
    cursor: pointer;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.25);
}
</style>
<template>
  <div class="service" v-show="show">
    <div class="closebtn" @click="show = false">
      <img src="../assets/closebtn.png" alt="" />
    </div>
    <div class="wave">
      <div class="w1"></div>
      <div class="w2"></div>
      <div class="w3"></div>
      <img src="../assets/avatar.png" class="avatar" />
    </div>
    <div class="title">师董会专属客服为您服务！</div>
    <div class="se">咨询客服：</div>
    <div class="tel">15519391960</div>
    <div>
      <el-button class="btn ml115" @click="showPopMessage">在线留言</el-button>
      <el-button class="btn" @click="showChat">立即咨询</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  mounted(){
    this.timer();
  },
  destroyed(){
    this.timer();
    clearInterval(this.timer());
  },
  methods: {
    timer() {
      setInterval(()=>{
        this.show = true;
      },12000);
    },
    showChat() {
      this.$store.commit("showChat");
    },
    showPopMessage() {
      this.$store.commit("showPopMessage");
    },
  },
};
</script>

<style lang="less" scoped>
.service {
  position: fixed;
  width: 540px;
  height: 200px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  bottom: 60px;
  left: 60px;
  z-index: 99;
  .closebtn {
    width: 22px;
    height: 22px;
    cursor: pointer;
    top: 21px;
    right: 25px;
    position: absolute;
    img {
      width: 100%;
    }
  }
  .avatar {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 28px;
    left: 26px;
  }
  .title {
    line-height: 80px;
    font-size: 20px;
    font-weight: 700;
    color: #333333;
    position: absolute;
    left: 115px;
  }
  .se,
  .tel {
    position: absolute;
    left: 115px;
    top: 80px;
    color: #666666;
  }
  .tel {
    left: 190px;
    color: #333333;
  }
  .btn {
    display: inline-block;
    width: 180px;
    height: 46px;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    border: 1px solid #ff7b00;
    color: #ff7b00;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    margin-top: 130px;
  }
  .btn:hover {
    background-color: #ff7b00;
    color: #ffffff;
  }
  .ml115 {
    margin-left: 115px;
  }
}
.wave {
  position: relative;
}
.wave div {
  position: absolute;
  border-radius: 38%;
}
.w1 {
  background: #ff7b00;
  transform: rotate(30deg);
  animation: drift1 4s infinite linear;
  width: 78px;
  height: 83px;
  top: 20px;
  left: 21px;
}
.w2 {
  background: #09beaf;
  transform: rotate(60deg);
  animation: drift2 6s infinite linear;
  width: 74px;
  height: 79px;
  top: 23px;
  left: 23px;
}
.w3 {
  background: #174fe6;
  transform: rotate(90deg);
  animation: drift3 5s infinite linear;
  width: 70px;
  height: 75px;
  top: 26px;
  left: 25px;
}
@keyframes drift1 {
  from {
    transform: rotate(30deg);
  }

  from {
    transform: rotate(390deg);
  }
}
@keyframes drift2 {
  from {
    transform: rotate(60deg);
  }

  from {
    transform: rotate(420deg);
  }
}
@keyframes drift3 {
  from {
    transform: rotate(90deg);
  }

  from {
    transform: rotate(450deg);
  }
}
</style>
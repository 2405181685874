(function() {
    const baseSize = 16
    function setRem() {
      let scale = document.documentElement.clientWidth / 1920
      scale = scale > 1 ? 1 : scale
      document.documentElement.style.fontSize = baseSize * Math.min(scale, 1.2) + 'px'
    }
    setRem()
    window.addEventListener('resize', () => { setRem() })
  })()
  
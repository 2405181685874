<template>
    <div>
      <div class="nav">
        <div class="menu-list">
          <div class="menu">
            <div class="menu-title"><router-link to="/">首页</router-link></div>
            <ul>
              <li><router-link to="/ipo-service">师董服务</router-link></li>
              <li><router-link to="/download">下载客户端</router-link></li>
              <li><router-link to="/about-us">关于我们</router-link></li>
            </ul>
          </div>
          <div class="menu">
            <div class="menu-title"><router-link to="/ipo-service">上市服务</router-link></div>
            <ul>
              <li><router-link to="/new-energy">晖阳新能源</router-link></li>
              <li><router-link to="/ipo-service">上市服务</router-link></li>
              <li><router-link to="/vip-service">会员服务</router-link></li>
              <li><router-link to="/headquarters">区域总部</router-link></li>
            </ul>
          </div>
          <div class="menu">
            <div class="menu-title"><router-link to="/resource">资源</router-link></div>
            <ul>
              <li><router-link to="/resource">优质资源</router-link></li>
              <li><router-link to="/vip-service">会员资源</router-link></li>
            </ul>
          </div>
          <div class="menu">
            <div class="menu-title"><router-link to="/news">动态</router-link></div>
            <ul>
              <li><router-link to="/news">新闻动态</router-link></li>
            </ul>
          </div>
        </div>
        <div class="qr-code">
          <div class="code">
            <img src="../assets/wx.png">
            <span>扫码关注师董会微信公众号</span>
          </div>
          <div class="code">
            <img src="../assets/app.png">
            <span>扫码下载师董会APP</span>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="address">
          <p>总&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;部：山东省淄博市张店区马尚街道办事处人民西路228号金融中心大厦15层1501室</p>
          <p>上海地址：上海市浦东区东方路985号一百杉杉大厦</p>
          <p>北京地址：北京市海淀区上地十街辉煌国际大厦东六号楼</p>
          <p>杭州地址：浙江省杭州市拱墅区远洋国际中心D幢1201室</p>
          <!-- <p>淄博地址：山东省淄博市张店区马尚街道办事处人民西路228号金融中心大厦15层1501室</p> -->
        </div>
        <div class="contact">
          <p>业务洽谈：15519391960</p>
          <p>邮箱：hyxny12345@163.com</p>
        </div>
        <div class="declare">
          <span><a href="https://beian.miit.gov.cn/">鲁ICP备 2023040594号</a></span>
          <span><a href="https://beian.mps.gov.cn/#/query/webSearch?code=37030302000962">鲁公网安备37030302000962</a></span>
          <span><router-link to="/declaraction">免责声明</router-link> | <router-link :to="{path:'/about-us', query: { contact: true }}" >联系我们</router-link> | 网站地图</span>
          <span>淄博师董数字科技服务有限公司 ©版权所有</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
.nav{
  height: 332px;
  background: #292929;
  padding-left: 360px;
  padding-right: 360px;
  padding-top: 73px;
}
.info{
  // height: 245px;
  background: #333333;
  padding-left: 360px;
  padding-right: 360px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.menu-list{
  float: left;
  // width: 530px;
}
.menu{
  display: inline-block;
  vertical-align: top;
  margin-right: 56px;
  .menu-title{
    line-height: 25px;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    position: relative;
  }
  .menu-title::after{
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    display: inline-block;
    width: 44px;
    height: 2px;
    background: #ff7b00;
  }
  ul{
    padding-left: 0;
  }
  li{
    list-style: none;
    line-height: 20px;
    font-size: 14px;
    color: #a5a5a5;
    margin-bottom: 8px;
  }
}
.address{
  display: inline-block;
  width: 640px;
  font-size: 16px;
  color: #a5a5a5;
  p{
    width: 640px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}
.contact{
  display: inline-block;
  margin-left: 165px;
  font-size: 16px;
  color: #a5a5a5;
  p{
    line-height: 22px;
    margin-bottom: 10px;
  }
}
.declare{
  line-height: 22px;
  font-size: 16px;
  color: #efefef;
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.qr-code{
  display: flex;
  justify-content: center;
  margin-left: 130px;
}
.code{
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  // width: 170px;
  text-align: center;
  margin-left: 53px;
  img{
    display: inline-block;
    width: 146px;
    height: 146px;
  }
  span{
    display: block;
    // width: 170px;
    margin-top: 13px;
  }
}
</style>
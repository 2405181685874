import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [{
        path: "/",
        component: () =>
            import ('@/views/home/index')
    },
    {
        path: "/more-tutor",
        component: () =>
            import ('@/views/home/more-tutor'),
        meta: {
            keepAlive: true //需要被缓存的组件
        }
    },
    {
        path: "/tutor-detail",
        component: () =>
            import ('@/views/home/tutor-detail')
    },
    {
        path: "/download",
        component: () =>
            import ('@/views/download/index')
    },
    {
        path: "/about-us",
        component: () =>
            import ('@/views/about/about-us')
    },
    {
        path: "/join-us",
        component: () =>
            import ('@/views/about/join-us')
    },
    {
        path: "/new-energy",
        component: () =>
            import ('@/views/sd-service/new-energy')
    },
    {
        path: "/ipo-service",
        component: () =>
            import ('@/views/sd-service/ipo-service')
    },
    {
        path: "/vip-service",
        component: () =>
            import ('@/views/sd-service/vip-service')
    },
    {
        path: "/headquarters",
        component: () =>
            import ('@/views/sd-service/headquarters')
    },
    {
        path: "/resource",
        component: () =>
            import ('@/views/sd-service/resource')
    },
    {
        path: "/news",
        component: () =>
            import ('@/views/sd-service/news')
    },
    {
        path: "/news-detail",
        component: () =>
            import ('@/views/sd-service/news-detail')
    },
    {
        path: "/declaraction",
        component: () =>
            import ('@/views/home/declaraction')
    },
    {
        path: "/membership",
        component: () =>
            import ('@/views/sd-service/membership')
    },
    {
        path: "/membership-detail",
        component: () =>
            import ('@/views/sd-service/membership-detail')
    },
]

var router = new VueRouter({
    mode: 'history',
    routes
})
export default router;
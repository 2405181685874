import axios from 'axios'
import { Message } from 'element-ui'
// create an axios instance
// https://shidonghui.sdh365.com/sdhui-sei/
console.log(process.env.NODE_ENV);
let baseURL = (process.env.NODE_ENV == 'development' || location.href.indexOf("https://www.shidonghui.cn/") > -1) ? "https://dev.shidonghui.cn/sdhui-sei/" : "https://shidonghui.sdh365.com/sdhui-sei/"
const service = axios.create({
    baseURL: baseURL, // url = base url + request url
    // baseURL: "https://dev.shidonghui.cn/sdhui-sei/", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
            // if the custom code is not 20000, it is judged as an error.
        if (res.rstCde !== 0) {
            Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        if (error.toString().indexOf("404") > -1) {
            Message({
                message: '请求失败！',
                type: 'error'
            })
        } else if (error.toString().indexOf("403") > -1) {
            Message({
                message: '没有访问权限',
                type: 'error'
            })
        } else if (error.toString().indexOf("401") > -1) {
            Message({
                message: '登录失效，请重新登陆',
                type: 'error'
            })
            location.reload()
        } else {
            Message({
                message: '请求异常！',
                type: 'error'
            })
        }
        return Promise.reject(error)
    }
)

export default service
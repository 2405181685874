<template>
    <div class="float" v-show="flag">
        <img src="../assets/float-code.png" class="code">
        <img src="../assets/close.png" class="close" @click="flag=false">
    </div>
</template>

<script>
export default {
    name:'FloatCode',
    data(){
        return{
            flag:true
        }
    }
}
</script>

<style lang="less" scoped>
.float{
    position: fixed;
    top: 575px;
    right: 165px;
    .code{
        width: 176px;
        height: 268px;
    }
    .close{
        width: 22px;
        height: 22px;
        position: relative;
        margin-left: -50%;
        margin-bottom: -30px;
        border-radius: 11px;
        box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.8);
        cursor: pointer;
    }
}
</style>